<template>
  <v-col cols="12">
    <v-card class="premium-features-card">
      <v-card-title class="d-flex align-center">
        <v-icon icon="mdi-star" color="warning" class="mr-2"></v-icon>
        {{ $t('premium.title') }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <div class="text-body-1 mb-4">
              {{ $t('premium.subtitle') }}
            </div>
            <v-list>
              <v-list-item prepend-icon="mdi-rocket">
                <v-list-item-title>{{ $t('premium.features.ai.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.ai.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-chart-box">
                <v-list-item-title>{{ $t('premium.features.recommendations.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.recommendations.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-database-search">
                <v-list-item-title>{{ $t('premium.features.search.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.search.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-file-document-outline">
                <v-list-item-title>{{ $t('premium.features.export.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.export.description') }}</v-list-item-subtitle>
              </v-list-item>
              <v-list-item prepend-icon="mdi-headset">
                <v-list-item-title>{{ $t('premium.features.support.title') }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('premium.features.support.description') }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col cols="12" md="4" class="d-flex align-center justify-center">
            <v-btn
              color="primary"
              size="large"
              @click="openModal"
              class="upgrade-btn"
            >
              <v-icon start icon="mdi-rocket-launch" class="mr-2"></v-icon>
              {{ $t('premium.upgradeButton') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>

  <SignupDialog v-model="showModal" />
</template>

<script setup>
import { ref } from 'vue'
import SignupDialog from '@/components/common/SignupDialog.vue'
import { trackSignupDialogOpen } from '@/utils/analytics'

const showModal = ref(false)

const openModal = () => {
  showModal.value = true
  trackSignupDialogOpen()
}

defineEmits(['update:showSignupDialog'])
</script>

<style scoped>
.premium-features-card {
  border: 1px solid var(--border-color);
  background: white;
  transition: all 0.3s ease;
}

.premium-features-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

.upgrade-btn {
  min-width: 200px;
  background-color: var(--primary) !important;
  color: white !important;
}

.upgrade-btn:hover {
  background-color: var(--primary-dark) !important;
}

:deep(.v-list-item-subtitle) {
  white-space: normal !important;
  -webkit-line-clamp: unset !important;
  -webkit-box-orient: unset !important;
  color: var(--text-primary) !important;
}

:deep(.v-list-item-title) {
  color: var(--text-primary);
  font-weight: 600;
}
</style>
