<template>
  <CommonArticle 
    content-id="sustainable-floorplan"
    :titles="{
      de: 'Nachhaltigkeit im Grundriss: Zukunftsorientiert Planen',
      en: 'Sustainability in Floor Plans: Future-Oriented Planning'
    }"
    :publicationDate="new Date('2024-03-22')"
  >
  </CommonArticle>
</template>

<script>
import CommonArticle from '@/components/articles/CommonArticle.vue'

export default {
  name: 'NachhaltigerGrundriss',
  components: {
    CommonArticle
  }
}
</script> 