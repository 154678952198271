<template>
  <v-dialog :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)" max-width="500px">
    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :timeout="4000"
      location="top"
      class="text-center"
    >
      {{ snackbar.text }}
    </v-snackbar>
    <v-card class="pa-4">
      <div class="d-flex justify-end">
        <v-btn
          icon="mdi-close"
          variant="text"
          size="x-small"
          class="dialog-close-btn"
          density="compact"
          @click="$emit('update:modelValue', false)"
        >
          <v-icon size="small">mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title class="text-h5 font-weight-bold text-center pt-0">
        {{ $t('signup.title') }}
      </v-card-title>
      <v-card-text class="text-center">
        <p class="mb-8">
          {{ $t('signup.description') }}
        </p>
        <v-text-field
          v-model="email"
          :label="$t('signup.email.label')"
          type="email"
          variant="outlined"
          :rules="[rules.required, rules.email]"
          class="mb-8"
        ></v-text-field>

        <ConsentManager
          ref="consentManager"
          @update:consent="updateConsent"
        />
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          @click="submitEmail"
          :loading="loading"
          class="px-6"
        >
          {{ $t('signup.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { trackSignupDialogOpen, trackSignupSubmit } from '@/utils/analytics';
import ConsentManager from './ConsentManager.vue';

const { t } = useI18n();

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  }
});

const emit = defineEmits(['update:modelValue']);

const email = ref('');
const loading = ref(false);
const consentManager = ref(null);
const userConsent = ref({
  marketing: false,
  privacy: false
});

const rules = {
  required: value => !!value || t('signup.email.rules.required'),
  email: value => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(value) || t('signup.email.rules.invalid');
  }
};

const snackbar = ref({
  show: false,
  text: '',
  color: 'success'
});

const showNotification = (text, color = 'success') => {
  snackbar.value = {
    show: true,
    text,
    color
  };
};

const updateConsent = (consent) => {
  userConsent.value = consent;
};

const submitEmail = async () => {
  if (!rules.email(email.value)) return;
  if (!consentManager.value?.isValid()) {
    showNotification(t('signup.consent.required'), 'error');
    return;
  }
  
  loading.value = true;
  try {
    await axios.post('/api/subscribe', {
      email: email.value,
      consent: userConsent.value
    });

    trackSignupSubmit(true);
    showNotification(t('signup.success'));
    setTimeout(() => {
      email.value = '';
      emit('update:modelValue', false);
    }, 2000);
  } catch (error) {
    trackSignupSubmit(false);
    showNotification(error.response?.data?.error || t('signup.error'), 'error');
  } finally {
    loading.value = false;
  }
};

// Track dialog opening
if (props.modelValue) {
  trackSignupDialogOpen();
}
</script>

<style scoped>
.v-dialog {
  border-radius: 16px;
}

:deep(.v-card) {
  border-radius: 16px !important;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-md) !important;
}

.dialog-close-btn {
  position: absolute;
  right: 8px;
  top: 8px;
  min-width: 24px !important;
  padding: 10;
}

.close-button:hover {
  opacity: 1;
}

:deep(.v-card-title) {
  color: var(--text-primary);
  font-weight: 700;
  font-size: 1.5rem !important;
  line-height: 1.3;
}

:deep(.v-card-text p) {
  color: var(--text-secondary);
  line-height: 1.6;
  font-size: 1rem;
}

:deep(.v-text-field) {
  border-radius: 8px;
}

:deep(.v-text-field .v-field) {
  border-radius: 8px !important;
  background-color: var(--background);
}

:deep(.v-text-field .v-field:hover) {
  border-color: var(--primary);
}

:deep(.v-text-field--focused .v-field) {
  border-color: var(--primary) !important;
}

:deep(.v-btn) {
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
  height: 48px;
  min-width: 160px;
}

:deep(.v-btn.v-btn--variant-flat) {
  background: var(--primary);
  color: white;
}

:deep(.v-btn.v-btn--variant-flat:hover) {
  background: var(--primary-dark);
}

:deep(.v-snackbar) {
  margin-top: 24px;
}

:deep(.v-snackbar .v-snackbar__content) {
  font-size: 0.95rem;
  font-weight: 500;
}
</style>
