<template>
  <div class="articles-page">
    <h1>{{ $t('articles.title') }}</h1>
    <div class="articles-grid">
      <router-link 
        v-for="article in localizedArticles" 
        :key="article.id"
        :to="article.path"
        class="article-card"
      >
        <img :src="article.image" :alt="article.title">
        <div class="article-info">
          <h2>{{ article.title }}</h2>
          <p class="article-date">{{ formatDate(article.publicationDate) }}</p>
          <p class="article-description">{{ article.description }}</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'Articles',
  setup() {
    const { locale } = useI18n()

    const articles = [
      {
        id: 1,
        titles: {
          de: 'Optimale Raumaufteilung für kleine Wohnungen',
          en: 'Optimal Room Layout for Small Apartments'
        },
        descriptions: {
          de: 'Erfahren Sie, wie Sie auch auf kleiner Fläche optimal leben können und welche Gestaltungsprinzipien dabei helfen.',
          en: 'Learn how to live optimally in small spaces and which design principles can help.'
        },
        image: '/images/articles/pexels-marco-ottaviano-2068087-7190383.jpg',
        path: '/artikel/optimal-floorplan',
        publicationDate: new Date('2024-12-20')
      },
      {
        id: 2,
        titles: {
          de: 'Nachhaltigkeit im Grundriss: Zukunftsorientiert Planen',
          en: 'Sustainability in Floor Plans: Future-Oriented Planning'
        },
        descriptions: {
          de: 'Wie Sie durch clevere Grundrissplanung Energie sparen und nachhaltig wohnen können.',
          en: 'How smart floor plan design can help you save energy and live sustainably.'
        },
        image: '/images/articles/pexels-laup-2976176.jpg',
        path: '/artikel/nachhaltiger-grundriss',
        publicationDate: new Date('2024-11-22')
      }
    ]

    const localizedArticles = computed(() => {
      return articles.map(article => ({
        ...article,
        title: article.titles[locale.value] || article.titles['de'],
        description: article.descriptions[locale.value] || article.descriptions['de']
      }))
    })

    return {
      localizedArticles,
      formatDate: (date) => {
        return new Intl.DateTimeFormat(locale.value, {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(date)
      }
    }
  }
}
</script>

<style scoped>
.articles-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
}

.article-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  text-decoration: none;
  color: inherit;
  background: white;
}

.article-card:hover {
  transform: translateY(-4px);
}

.article-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.article-info {
  padding: 1.5rem;
}

.article-info h2 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
  font-size: 1.25rem;
}

.article-date {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.article-description {
  color: #444;
  line-height: 1.4;
}
</style> 