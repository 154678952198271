<template>
  <div class="analysis">
    <v-container>
      <v-row class="mb-4">
        <v-col cols="12">
          <h1 class="text-h6 mb-2">{{ $t('analysis.title') }}</h1>
          <p class="text-subtitle-1">{{ $t('analysis.subtitle') }}</p>
        </v-col>
      </v-row>

      <template v-if="!analysis">
        <v-row>
          <v-col cols="12" class="text-center">
            <v-card class="pa-6">
              <div class="d-flex align-center justify-center flex-column">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  size="64"
                  class="mb-4"
                ></v-progress-circular>
                <h3 class="text-h5 mb-3">{{ $t('analysis.loading.title') }}</h3>
                <div class="text-body-1 text-medium-emphasis mb-2">
                  {{ $t('analysis.loading.message1') }}
                </div>
                <div class="text-body-2 text-medium-emphasis">
                  {{ $t('analysis.loading.message2') }}
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </template>

      <template v-else>
        <v-row class="floorplan-row">
          <FloorplanPreview :imageUrl="imageUrl" class="mb-4" />
          <AnalysisResults :results="results" />
        </v-row>

        <v-row class="mt-6">
          <CostEfficiencyAnalysis :imageUrl="imageUrl" />
        </v-row>

        <v-row class="mt-6">
          <RoomEvaluation :imageUrl="imageUrl" />
        </v-row>

        <v-row class="mt-6">
          <ImprovementSuggestions :suggestions="suggestions" />
        </v-row>

        <v-row class="mt-6">
          <PremiumFeatures v-model:showSignupDialog="showSignupDialog" />
        </v-row>

        <SignupDialog v-model="showSignupDialog" />
      </template>
    </v-container>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import SignupDialog from '@/components/common/SignupDialog.vue'
import FloorplanPreview from '@/components/FloorplanPreview.vue'
import AnalysisResults from '@/components/AnalysisResults.vue'
import ImprovementSuggestions from '@/components/ImprovementSuggestions.vue'
import PremiumFeatures from '@/components/PremiumFeatures.vue'
import CostEfficiencyAnalysis from '@/components/CostEfficiencyAnalysis.vue'
import RoomEvaluation from '@/components/RoomEvaluation.vue'

const store = useStore()
const router = useRouter()

const showSignupDialog = ref(false)

// Computed properties for data from Vuex
const analysis = computed(() => store.state.analysis)
const results = computed(() => analysis.value?.analysis?.results || [])
const suggestions = computed(() => analysis.value?.analysis?.suggestions || [])
const imageUrl = computed(() => analysis.value?.imageUrl)

// Check for analysis data
onMounted(() => {
  if (!analysis.value) {
    router.push('/')
  }
})
</script>

<style scoped>
.analysis {
  min-height: 100vh;
  background-color: var(--background);
}

/* Typography overrides */
h1 {
  color: var(--text-primary);
}

p {
  color: var(--text-secondary);
}

:deep(.v-card) {
  border-radius: 16px;
  transition: all 0.3s ease;
}

:deep(.v-card:hover) {
  box-shadow: var(--shadow-md);
}
</style>
