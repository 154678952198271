<template>
  <v-row id="testimonials" class="testimonials-section py-12">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold">{{ $t('testimonials.title') }}</h2>
          <p class="text-subtitle-1 mt-2">{{ $t('testimonials.subtitle') }}</p>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col
          v-for="(testimonial, key) in testimonials"
          :key="key"
          cols="12"
          md="4"
          class="d-flex"
        >
          <v-card class="testimonial-card" elevation="2">
            <v-card-item>
              <div class="d-flex align-center mb-4">
                <v-avatar color="primary" class="mr-4">
                  <span class="text-h6 font-weight-bold">{{ $t(`testimonials.items.${key}.name`).charAt(0) }}</span>
                </v-avatar>
                <div>
                  <div class="font-weight-bold">{{ $t(`testimonials.items.${key}.name`) }}</div>
                  <div class="text-caption text-medium-emphasis">{{ $t(`testimonials.items.${key}.title`) }}</div>
                </div>
              </div>
              <v-rating
                :model-value="5"
                color="warning"
                density="compact"
                readonly
                size="small"
              ></v-rating>
              <p class="text-body-1 mt-4">{{ $t(`testimonials.items.${key}.content`) }}</p>
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script setup>
const testimonials = {
  builder: {},
  architect: {},
  realtor: {}
};
</script>

<style scoped>
.testimonials-section {
  background: white;
  padding: 5rem 0;
}

.text-h4 {
  color: var(--text-primary);
  font-weight: 700;
  margin-bottom: 1rem;
}

.text-subtitle-1 {
  color: var(--text-secondary);
  font-size: 1.125rem;
  line-height: 1.6;
}

.testimonial-card {
  height: 100%;
  border-radius: 16px !important;
  border: 1px solid var(--border-color);
  background: var(--background);
  transition: all 0.3s ease;
}

.testimonial-card:hover {
  transform: translateY(-5px);
  box-shadow: var(--shadow-md);
}

:deep(.v-card-item) {
  padding: 2rem !important;
}

.v-avatar {
  background: var(--primary) !important;
  font-weight: 600;
}

.font-weight-bold {
  color: var(--text-primary);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.4;
}

.text-caption {
  color: var(--text-secondary);
  font-size: 0.875rem;
}

.text-body-1 {
  color: var(--text-secondary);
  line-height: 1.6;
  margin-top: 1rem;
  font-size: 1rem;
}

:deep(.v-rating .v-icon) {
  color: #fbbf24 !important;
  opacity: 1;
}

:deep(.v-avatar) {
  color: white !important;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  h2 {
    font-size: 2.125rem !important;
  }
}
</style>
