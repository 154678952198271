<template>
  <section class="upload-section">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="8" lg="6">
          <UploadArea
            @upload-success="handleUploadSuccess"
            @upload-error="handleUploadError"
          />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import UploadArea from '@/components/upload/UploadArea.vue';

const router = useRouter();
const store = useStore();

const handleUploadSuccess = (validatedData) => {
  store.commit('SET_ANALYSIS', validatedData);
  router.push({ name: 'Analysis' });
};

const handleUploadError = (error) => {
  console.error('Upload error:', error);
};
</script>

<style scoped>
.upload-section {
  padding: 2rem 0 7rem 0;
  background: var(--background);
}
</style> 