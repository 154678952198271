const STORAGE_KEY = 'user-language'
const SUPPORTED_LANGUAGES = ['en', 'de']

/**
 * Gets the base language code from a locale string
 * e.g., 'en-US' -> 'en', 'de-DE' -> 'de'
 */
function getBaseLanguage(locale) {
  return locale.split('-')[0]
}

/**
 * Determines if a language is supported by the application
 */
function isLanguageSupported(lang) {
  return SUPPORTED_LANGUAGES.includes(getBaseLanguage(lang))
}

/**
 * Gets the user's preferred language based on various sources
 * 1. Saved preference in localStorage
 * 2. Browser language settings
 * 3. Default fallback (en)
 */
export function detectUserLanguage() {
  // 1. Check localStorage
  const savedLanguage = localStorage.getItem(STORAGE_KEY)
  if (savedLanguage && isLanguageSupported(savedLanguage)) {
    return savedLanguage
  }

  // 2. Check browser settings
  if (typeof window !== 'undefined' && window.navigator) {
    // First check navigator.languages (returns array of preferred languages)
    if (navigator.languages && navigator.languages.length) {
      for (const lang of navigator.languages) {
        const baseLanguage = getBaseLanguage(lang)
        if (isLanguageSupported(baseLanguage)) {
          return baseLanguage
        }
      }
    }
    
    // Fallback to navigator.language or navigator.userLanguage
    const browserLanguage = navigator.language || navigator.userLanguage
    if (browserLanguage && isLanguageSupported(browserLanguage)) {
      return getBaseLanguage(browserLanguage)
    }
  }

  // 3. Default fallback
  return 'en'
}

/**
 * Saves the user's language preference
 */
export function saveLanguagePreference(lang) {
  localStorage.setItem(STORAGE_KEY, lang)
}
