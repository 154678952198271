<template>
  <CommonArticle 
    content-id="optimal-floorplan"
    :titles="{
      de: 'Optimale Raumaufteilung für kleine Wohnungen',
      en: 'Optimal Room Layout for Small Apartments'
    }"
    :publicationDate="new Date('2024-03-20')"
  >
  </CommonArticle>
</template>

<script>
import CommonArticle from '@/components/articles/CommonArticle.vue'

export default {
  name: 'OptimaleRaumaufteilung',
  components: {
    CommonArticle
  }
}
</script> 