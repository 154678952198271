<template>
  <v-card class="upload-card" elevation="0" :data-popular-text="t('hero.upload.free_trial_badge')">
    <v-card-text>
      <div 
        class="upload-zone pa-8"
        :class="{ 'drag-over': isDragging }"
        @dragenter.prevent="isDragging = true"
        @dragover.prevent="isDragging = true"
        @dragleave.prevent="isDragging = false"
        @drop.prevent="handleDrop"
        @click="triggerFileInput"
      >
        <v-icon size="64" :color="isUploading ? (progress <= 33 ? 'blue' : progress <= 66 ? 'orange' : progress <= 99 ? 'green' : 'primary') : 'grey-lighten-1'">
          {{ isUploading ? (progress <= 33 ? 'mdi-cloud-upload' : progress <= 66 ? 'mdi-cog' : progress <= 99 ? 'mdi-rocket' : 'mdi-check-circle') : 'mdi-cloud-upload' }}
        </v-icon>
        <div class="d-flex align-center justify-center mt-4">
          <span class="text-body-1">
            {{ uploadStatusText }}
          </span>
          <v-progress-circular
            v-if="isUploading"
            :size="16"
            :width="2"
            color="primary"
            indeterminate
            class="ml-2"
          ></v-progress-circular>
        </div>
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          class="hidden-input"
          @change="handleFileInput"
        >
      </div>
      
      <v-alert
        v-if="errorMessage"
        type="error"
        class="mt-4"
        variant="tonal"
      >
        {{ errorMessage }}
      </v-alert>
      
      <div v-if="selectedFile" class="selected-file mt-4">
        <v-chip
          closable
          @click:close="clearFile"
          color="primary"
          variant="outlined"
          class="file-chip"
        >
          {{ selectedFile.name }} ({{ formatFileSize(selectedFile.size) }})
        </v-chip>
      </div>
      <v-progress-linear 
        v-if="isUploading" 
        v-model="progress" 
        :key="progress"
        height="10" 
        color="primary"
        class="mt-4"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { trackImageUpload } from '@/utils/analytics';
import { useAuthStore } from '@/stores/auth';
// import { useRouter } from 'vue-router';
import { getIdToken } from 'firebase/auth';

const emit = defineEmits(['upload-success', 'upload-error']);
const { t, locale } = useI18n();

const fileInput = ref(null);
const selectedFile = ref(null);
const isDragging = ref(false);
const isUploading = ref(false);
const errorMessage = ref('');
const progress = ref(0);

const authStore = useAuthStore();
// const router = useRouter();

const uploadStatusText = computed(() => {
  if (!isUploading.value) {
    return t('hero.upload.dragDrop');
  }
  
  if (progress.value <= 10) {
    return t('hero.upload.stages.analyzing');
  } else if (progress.value <= 20) {
    return t('hero.upload.stages.layoutAnalysis');
  } else if (progress.value <= 30) {
    return t('hero.upload.stages.spaceEfficiency');
  } else if (progress.value <= 40) {
    return t('hero.upload.stages.lightingAnalysis');
  } else if (progress.value <= 50) {
    return t('hero.upload.stages.functionalityCheck');
  } else if (progress.value <= 60) {
    return t('hero.upload.stages.costAnalysis');
  } else if (progress.value <= 70) {
    return t('hero.upload.stages.energyAnalysis');
  } else if (progress.value <= 80) {
    return t('hero.upload.stages.roomEvaluation');
  } else if (progress.value <= 90) {
    return t('hero.upload.stages.generatingSuggestions');
  } else if (progress.value <= 99) {
    return t('hero.upload.stages.finishing');
  }
  return t('hero.upload.stages.creating');
});

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileInput = async (event) => {
  const file = event.target.files[0];
  if (file) {
    await validateAndSetFile(file);
    if (selectedFile.value) {
      handleUpload();
    }
  }
};

const handleDrop = async (event) => {
  isDragging.value = false;
  const file = event.dataTransfer.files[0];
  if (file) {
    await validateAndSetFile(file);
    if (selectedFile.value) {
      handleUpload();
    }
  }
};

const validateAndSetFile = (file) => {
  errorMessage.value = '';
  
  const validTypes = ['image/jpeg', 'image/png', 'application/pdf'];
  const maxSize = 10 * 1024 * 1024; // 10MB
  
  if (!validTypes.includes(file.type)) {
    errorMessage.value = t('hero.upload.errors.fileType');
    return;
  }
  
  if (file.size > maxSize) {
    errorMessage.value = t('hero.upload.errors.fileSize');
    return;
  }
  
  selectedFile.value = file;
};

const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const clearFile = () => {
  selectedFile.value = null;
  errorMessage.value = '';
  if (fileInput.value) {
    fileInput.value.value = '';
  }
};

function startProgressAnimation() {
  const interval = setInterval(() => {
    progress.value += 1;
    if (progress.value >= 300) {
      clearInterval(interval);
    }
  }, 600);
}

const uploadFile = async () => {
  isUploading.value = true;
  startProgressAnimation();
  try {
    const formData = new FormData();
    formData.append('floorplan', selectedFile.value);
    formData.append('language', locale.value);
    
    // Add auth headers if authenticated
    let headers = { 'Content-Type': 'multipart/form-data' };
    if (authStore.isAuthenticated) {
      const token = await getIdToken(authStore.user);
      headers.Authorization = `Bearer ${token}`;
    }
    
    const response = await axios.post('/api/upload', formData, {
      headers
    });

    const validatedData = response.data;
    
    // Track successful upload
    trackImageUpload(selectedFile.value.size, true);
    
    emit('upload-success', validatedData);
  } catch (error) {
    // Track failed upload
    trackImageUpload(selectedFile.value.size, false);
    
    if (error.response) {
      const responseData = error.response.data;
      if (responseData?.isI18nKey) {
        errorMessage.value = t(responseData.message);
      } else {
        errorMessage.value = responseData?.error || responseData?.message || t('hero.upload.errors.uploadError');
      }
    } else {
      errorMessage.value = t('hero.upload.errors.networkError');
    }
    emit('upload-error', errorMessage.value);
  } finally {
    isUploading.value = false;
    progress.value = 0;
  }
};

const handleUpload = () => {
  // UPLOAD is currently in FREE TRIAL MODE activated
  //if (!authStore.isAuthenticated) {
  //  router.push('/sign-in');
  //  return;
  //}
  uploadFile();
};
</script>

<style scoped>
.upload-card {
  background: white;
  border: 2px dashed var(--border-color);
  border-radius: 16px !important;
  transition: all 0.3s ease;
  animation: glowingBorder 2s ease-in-out infinite;
  box-shadow: 0 0 20px 0 var(--primary);
  position: relative;
}

@keyframes glowingBorder {
  0% {
    box-shadow: 0 0 0 0 var(--primary);
    border-color: var(--border-color);
  }
  50% {
    box-shadow: 0 0 15px 0 var(--primary);
    border-color: var(--primary);
  }
  100% {
    box-shadow: 0 0 0 0 var(--primary);
    border-color: var(--border-color);
  }
}

.upload-zone {
  cursor: pointer;
  text-align: center;
  transition: all 0.3s ease;
  border-radius: 12px;
}

.upload-zone:hover {
  background: var(--background);
}

.drag-over {
  background: var(--background);
  border-color: var(--primary);
}

.upload-zone .v-icon {
  transition: all 0.3s ease;
}

.upload-zone:hover .v-icon {
  color: var(--primary) !important;
  transform: translateY(-5px);
}

.hidden-input {
  display: none;
}

.file-chip {
  background: white !important;
  border-color: var(--primary) !important;
  color: var(--primary) !important;
  font-weight: 500;
}

:deep(.v-alert) {
  border-radius: 12px;
}

:deep(.v-alert.v-alert--variant-tonal) {
  background: #fee2e2;
  color: #991b1b;
}

.upload-card:hover {
  border-color: var(--primary);
  animation: none;
  box-shadow: 0 0 20px 0 var(--primary);
}

.upload-card::before {
  content: attr(data-popular-text);
  position: absolute;
  top: 8px;
  right: 8px;
  background: var(--primary);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  z-index: 1;
}
</style> 