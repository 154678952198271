<template>
  <SignupDialog v-model="showSignupDialog" />
  
  <v-col cols="12">
    <v-card class="cost-analysis pa-4">
      <v-card-title>{{ $t('analysis.costEfficiency.title') }}</v-card-title>

      <template v-if="loading">
        <div class="d-flex flex-column align-center ma-4">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-4"
          ></v-progress-circular>
          <span class="text-medium-emphasis">{{ $t('analysis.costEfficiency.loading') }}</span>
        </div>        
      </template>

      <template v-else-if="error">
        <div class="text-center error--text">
          {{ error }}
        </div>
      </template>
      
      <template v-else-if="analysis">
        <!-- Summary -->
        <v-card-text class="analysis-summary mt-4 premium-item">
          <div class="text-subtitle-1 font-weight-bold mb-2">
            {{ $t('analysis.costEfficiency.sections.summary.title') }}
          </div>
          <div class="description-container">
            <p class="text-body-2 premium-content">{{ analysis.summary }}</p>
            <div 
              class="premium-message"
              @click="showSignupDialog = true"
            >
              <div class="premium-message-content">
                <v-icon icon="mdi-lock" size="small" class="mr-2"></v-icon>
                <span>{{ $t('analysis.results.premiumFeature') }}</span>
              </div>
            </div>
          </div>
        </v-card-text>

        <!-- Space Efficiency -->
        <v-card-text class="analysis-section">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.spaceEfficiency.title') }}
            </h3>
            <v-rating
              :model-value="analysis.spaceEfficiency.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
          <p class="text-body-2">{{ analysis.spaceEfficiency.description }}</p>
        </v-card-text>

        <!-- Construction Cost -->
        <v-card-text class="analysis-section premium-item">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.constructionCost.title') }}
            </h3>
            <v-rating
              :model-value="analysis.constructionCost.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
          <PremiumContent 
            :is-premium="true"
            @upgrade="showSignupDialog = true"
          >
            <p class="text-body-2">
              {{ analysis.constructionCost.description }}
            </p>
          </PremiumContent>
        </v-card-text>

        <!-- Energy Efficiency -->
        <v-card-text class="analysis-section premium-item">
          <div class="d-flex justify-space-between align-center mb-2">
            <h3 class="text-subtitle-1 font-weight-bold">
              {{ $t('analysis.costEfficiency.sections.energyEfficiency.title') }}
            </h3>
            <v-rating
              :model-value="analysis.energyEfficiency.score"
              readonly
              density="compact"
              color="primary"
              length="5"
            ></v-rating>
          </div>
          <div class="description-container">
            <p class="text-body-2 premium-content">
              {{ analysis.energyEfficiency.description }}
            </p>
            <div 
              class="premium-message"
              @click="showSignupDialog = true"
            >
              <div class="premium-message-content">
                <v-icon icon="mdi-lock" size="small" class="mr-2"></v-icon>
                <span>{{ $t('analysis.results.premiumFeature') }}</span>
              </div>
            </div>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </v-col>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { useI18n } from 'vue-i18n'
import SignupDialog from '@/components/common/SignupDialog.vue'
import PremiumContent from '@/components/common/PremiumContent.vue'

const { t, locale } = useI18n()
const props = defineProps({
  imageUrl: {
    type: String,
    required: true
  }
})

const loading = ref(true)
const error = ref(null)
const analysis = ref({
  spaceEfficiency: { score: 0, description: '' },
  constructionCost: { score: 0, description: '' },
  energyEfficiency: { score: 0, description: '' },
  summary: ''
})

const showSignupDialog = ref(false)

const fetchCostAnalysis = async () => {
  try {
    const response = await axios.post('/api/analyze-cost', {
      imageUrl: props.imageUrl,
      language: locale.value
    })
    if (response.data?.data) {
      analysis.value = response.data.data
    } else {
      throw new Error('analysis.costEfficiency.error.invalidResponse')
    }
  } catch (err) {
    error.value = err.message === 'analysis.costEfficiency.error.invalidResponse' 
      ? t('analysis.costEfficiency.error.invalidResponse')
      : t('analysis.costEfficiency.error.default')
    console.error('Cost analysis error:', err)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  if (props.imageUrl) {
    fetchCostAnalysis()
  }
})
</script>

<style scoped>
.cost-analysis {
  border-radius: 8px;
}

.analysis-section {
  border-bottom: 1px solid var(--v-border-color);
  padding-top: 16px;
  padding-bottom: 16px;
}

.analysis-section:last-child {
  border-bottom: none;
}

.analysis-summary {
  background-color: var(--v-surface-variant);
  border-radius: 4px;
  position: relative;
}

:deep(.text-medium-emphasis) {
  opacity: 0.7;
  font-size: 0.9em;
}

.description-container {
  position: relative;
}

.premium-content {
  user-select: none;
  filter: blur(4px);
}

.premium-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px 12px;
  font-size: 0.85rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.premium-message-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}
</style>
