<template>
  <v-col cols="12" md="6">
    <v-card class="floorplan-card">
      <v-card-title>{{ $t('analysis.preview.title') }}</v-card-title>
      <v-card-text class="floorplan-container">
        <v-img
          :src="imageUrl"
          class="floorplan-image"
          contain
          :aspect-ratio="16/9"
        ></v-img>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const imageUrl = computed(() => store.state.analysis?.imageUrl)
</script>

<style scoped>
.floorplan-card {
  width: 100%;
  height: 100%;
}

.floorplan-container {
  min-height: 250px;
  height: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.floorplan-image {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
}

@media (max-width: 600px) {
  .floorplan-container {
    min-height: 200px;
  }
}
</style>
