import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCPOocqQ--qJ8zPZzO1jnTxCyjFMSN-AGo",
    authDomain: "grundriss-ai.firebaseapp.com",
    projectId: "grundriss-ai",
    storageBucket: "grundriss-ai.firebasestorage.app",
    messagingSenderId: "49316525756",
    appId: "1:49316525756:web:d1a94f79033279abfe82b9",
    measurementId: "G-YEN1C7TXDD"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth }; 