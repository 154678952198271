<template>
  <SignupDialog v-model="showSignupDialog" />
  
  <v-col cols="12" md="6">
    <v-card class="analysis-card">
      <v-card-title>{{ $t('analysis.results.title') }}</v-card-title>
      <v-card-text class="analysis-container">
        <v-expansion-panels v-if="analysisCategories.length > 0">
          <v-expansion-panel
            v-for="category in analysisCategories"
            :key="category.name"
          >
            <v-expansion-panel-title>
              <div class="d-flex align-center justify-space-between w-100">
                <div class="d-flex align-center">
                  <v-icon :icon="getIconForCategory(category.name)" color="primary" class="analysis-icon"></v-icon>
                  <div>{{ $t(`analysis.results.categories.${category.name}`) }}</div>
                </div>
                <v-rating
                  :model-value="calculateCategoryScore(category)"
                  readonly
                  density="compact"
                  color="primary"
                  length="5"
                ></v-rating>
              </div>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
              <div class="category-description mb-4">{{ category.description }}</div>
              <v-list>
                <v-list-item
                  v-for="(aspect, key) in category.aspects"
                  :key="key"
                  class="analysis-item"
                  :class="{ 'premium-item': isPremiumCategory(category.name) }"
                >
                  <v-tooltip
                    location="right"
                    :text="aspect.criteria"
                    max-width="300"
                    class="rating-tooltip"
                  >
                    <template v-slot:activator="{ props }">
                      <div v-bind="props">
                        <v-list-item-title class="font-weight-bold mb-2">
                          {{ $t(`analysis.results.aspects.${key}`) }}
                          <v-chip
                            :color="getRatingColor(aspect.rating)"
                            size="small"
                            class="ml-2"
                          >
                            {{ $t(`analysis.results.ratings.${aspect.rating}`) }}
                          </v-chip>
                        </v-list-item-title>
                        <PremiumContent 
                          :is-premium="isPremiumCategory(category.name)"
                          @upgrade="showSignupDialog = true"
                        >
                          <v-list-item-subtitle class="analysis-description text-wrap">
                            {{ aspect.description }}
                          </v-list-item-subtitle>
                        </PremiumContent>
                      </div>
                    </template>
                  </v-tooltip>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="text-center pa-4">
          {{ $t('analysis.results.noResults') }}
        </div>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import SignupDialog from '@/components/common/SignupDialog.vue'
import PremiumContent from '@/components/common/PremiumContent.vue'

const store = useStore()
const analysis = computed(() => store.state.analysis?.analysis || {})

// Calculate the average score for a category based on its aspects
const calculateCategoryScore = (category) => {
  if (!category.aspects || Object.keys(category.aspects).length === 0) return 0
  
  const ratings = Object.values(category.aspects).map(aspect => {
    // Convert text ratings to numeric scores (1-5 scale)
    switch(aspect.rating.toLowerCase()) {
      case 'positive': return 5
      case 'neutral': return 3
      case 'negative': return 1
      default: return 0
    }
  })
  
  const average = ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length
  return Math.round(average * 2) / 2
}

const analysisCategories = computed(() => {
  const categories = [
    'raumaufteilung',
    'raumfluss',
    'lichteinfall',
    'funktionalitaet',
    'stauraum',
    'privatsphaere',
    'zugaenglichkeit',
    'flexibilitaet'
  ]
  
  return categories
    .filter(cat => analysis.value[cat])
    .map(cat => {
      const category = analysis.value[cat]
      const aspects = {}
      
      Object.entries(category).forEach(([key, value]) => {
        if (key !== 'description' && typeof value === 'object') {
          aspects[key] = {
            ...value,
            criteria: extractCriteriaFromDescription(value.description)
          }
        }
      })
      
      return {
        name: cat,
        description: category.description,
        aspects
      }
    })
})

const extractCriteriaFromDescription = (description) => {
  if (!description) return ''
  const parts = description.split('\n\n')
  return parts[1] || ''
}

const getIconForCategory = (category) => {
  const categoryIcons = {
    raumaufteilung: 'mdi-floor-plan',
    raumfluss: 'mdi-routes',
    lichteinfall: 'mdi-lightbulb',
    funktionalitaet: 'mdi-lightning-bolt',
    stauraum: 'mdi-package-variant-closed',
    privatsphaere: 'mdi-shield-account',
    zugaenglichkeit: 'mdi-account-outline',
    flexibilitaet: 'mdi-sync',
    default: 'mdi-home'
  }
  return categoryIcons[category] || categoryIcons.default
}

const getRatingColor = (rating) => {
  const colors = {
    positive: 'success',
    neutral: 'warning',
    negative: 'error'
  }
  return colors[rating] || 'info'
}

// Add this constant for premium categories
const PREMIUM_CATEGORIES = [
  'funktionalitaet',
  'stauraum',
  'privatsphaere',
  'zugaenglichkeit',
  'flexibilitaet'
]

// Add this helper function
const isPremiumCategory = (category) => PREMIUM_CATEGORIES.includes(category)

// Add ref for dialog
const showSignupDialog = ref(false)
</script>

<style scoped>
.analysis-container {
  max-height: 800px;
  overflow-y: auto;
}

.analysis-item {
  margin-bottom: 16px;
  transition: all 0.3s ease;
  padding: 8px;
  border-radius: 4px;
}

.analysis-item:hover {
  background-color: var(--background);
}

.analysis-icon {
  margin-right: 12px;
  color: var(--primary);
}

.analysis-description {
  white-space: normal !important;
  margin-top: 4px;
  color: var(--text-primary);
  overflow: visible;
  text-overflow: unset;
  word-wrap: break-word;
}

:deep(.v-list-item-subtitle) {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: unset !important;
  -webkit-line-clamp: unset !important;
}

.category-description {
  color: var(--text-secondary);
  font-style: italic;
}

.rating-tooltip {
  white-space: pre-line;
}

.description-text {
  position: relative;
}

.premium-content {
  user-select: none;
  filter: blur(4px);
}

.premium-message {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px 8px;
  font-size: 0.85rem;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.premium-message:hover {
  background: rgba(255, 255, 255, 1);
}

.premium-item {
  position: relative;
}

.premium-item:hover .premium-message {
  display: flex;
}
</style>
