import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { auth } from '@/firebase/config';
import { onAuthStateChanged } from 'firebase/auth';

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null);
  const loading = ref(true);

  const isAuthenticated = computed(() => !!user.value);

  // Initialize auth state listener
  onAuthStateChanged(auth, (firebaseUser) => {
    user.value = firebaseUser;
    loading.value = false;
  });

  return {
    user,
    loading,
    isAuthenticated,
  };
}); 