import { createStore } from 'vuex';

export default createStore({
  state: {
    analysis: null,
    loading: false,
    error: null
  },
  mutations: {
    SET_ANALYSIS(state, analysis) {
      state.analysis = analysis;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    }
  },
  actions: {
    setAnalysis({ commit }, analysis) {
      commit('SET_ANALYSIS', analysis);
    },
    setLoading({ commit }, loading) {
      commit('SET_LOADING', loading);
    },
    setError({ commit }, error) {
      commit('SET_ERROR', error);
    }
  },
  getters: {
    getAnalysis: state => state.analysis,
    isLoading: state => state.loading,
    getError: state => state.error
  },
  modules: {
  }
});
