import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'

export default createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#2D5362',      // Tiefes Petrol - modern und vertrauenswürdig
          'primary-darken-1': '#1D3741', // Dunkleres Petrol für Hover
          secondary: '#E8A87C',    // Warmes Apricot - freundlich und einladend
          'secondary-darken-1': '#D99B6E', // Dunkleres Apricot für Hover
          accent: '#41B3A3',       // Minzgrün - frisch und innovativ
          'accent-darken-1': '#339E8E',  // Dunkleres Minzgrün für Hover
          info: '#84B1CA',         // Helles Blaugrau - informativ
          success: '#7FB069',      // Naturgrün - positiv
          warning: '#E27D60',      // Koralle - aufmerksamkeitserregend
          error: '#C38D9E',        // Mauve - dezent warnend
          background: '#F8F9FA',   // Helles Grau - clean und modern
          surface: '#FFFFFF',      // Weiß - clean
          'surface-variant': '#E8ECF1', // Helles Blaugrau - subtile Variation
          'on-surface-variant': '#424242' // Dunkelgrau - gute Lesbarkeit
        }
      }
    }
  }
})
