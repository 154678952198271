<template>
  <v-container class="feature-demo py-12">
    <v-row justify="center" class="mb-8">
      <v-col cols="12" class="text-center">
        <h2 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold">{{ $t('featureDemo.heading') }}</h2>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" md="10">
        <v-carousel
          hide-delimiters
          :show-arrows="true"
          height="600"
          class="custom-carousel"
        >
          <v-carousel-item
            v-for="(feature, i) in features"
            :key="i"
          >
            <v-row class="fill-height" align="center">
              <v-col cols="12" class="text-center mb-4">
                <h3 class="text-h5 font-weight-bold mb-2">{{ feature.title }}</h3>
                <p class="text-subtitle-1">{{ feature.description }}</p>
              </v-col>
              <v-col cols="12">
                <v-img
                  :src="feature.image"
                  max-height="400"
                  contain
                  class="mx-auto"
                ></v-img>
              </v-col>
            </v-row>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'FeatureDemoSection',
  data() {
    return {
      features: [        
        {
          title: this.$t('analysis.results.title'),
          description: this.$t('analysis.features.analyse'),
          image: this.getLocalizedImage('karusell_analyse_ergebnisse_3')
        },  
        {
          title: this.$t('analysis.results.categories.raumaufteilung'),
          description: this.$t('analysis.features.raumaufteilung'),
          image: this.getLocalizedImage('karusell_raumaufteilung')
        },
        {
          title: this.$t('analysis.results.categories.raumfluss'),
          description: this.$t('analysis.features.raumfluss'),
          image: this.getLocalizedImage('karusell_raumfluss')
        },
        {
          title: this.$t('analysis.results.categories.flexibilitaet'),
          description: this.$t('analysis.features.flexibilitaet'),
          image: this.getLocalizedImage('karusell_flexibilität')
        },
        {
          title: this.$t('analysis.costEfficiency.sections.constructionCost.title'),
          description: this.$t('analysis.features.baukosten'),
          image: this.getLocalizedImage('karusell_baukosten')
        },      
        {
          title: this.$t('analysis.roomEval.size'),
          description: this.$t('analysis.features.raumgroesse'),
          image: this.getLocalizedImage('karusell_raumgroesse')
        },
        {
          title: this.$t('analysis.improvements.title'),
          description: this.$t('analysis.features.verbesserungen'),
          image: this.getLocalizedImage('karusell_verbesserungen3')
        },
        {
          title: this.$t('analysis.improvements.title'),
          description: this.$t('analysis.features.verbesserungen'),
          image: this.getLocalizedImage('karusell_verbesserungen2')
        },
        {
          title: this.$t('analysis.improvements.title'),
          description: this.$t('analysis.features.verbesserungen'),
          image: this.getLocalizedImage('karusell_verbesserungen1')
        }
      ]
    }
  },
  methods: {
    getLocalizedImage(baseName) {
      const locale = this.$i18n.locale
      const suffix = locale === 'en' ? '_en' : ''
      return `/images/${baseName}${suffix}.png`
    }
  }
}
</script> 

<style scoped>
.custom-carousel :deep(.v-btn--icon) {
  background-color: transparent !important;
  border: none;
  height: 48px !important;
  width: 48px !important;
  margin: 0 1rem;
  box-shadow: none !important;
}

.custom-carousel :deep(.v-btn__content) {
  color: rgba(0, 0, 0, 0.5);
  font-size: 32px;
  font-weight: bold;
}

.custom-carousel :deep(.v-btn--icon:hover) .v-btn__content {
  color: rgba(0, 0, 0, 0.8);
}

.custom-carousel :deep(.v-window__prev) {
  left: -50px;
}

.custom-carousel :deep(.v-window__next) {
  right: -50px;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  h2 {
    font-size: 2.125rem !important;
  }
}
</style> 