<template>
  <div class="article-container">
    <div class="article-header">
      <h1>{{ currentTitle }}</h1>
      <div class="article-meta">
        <span class="publication-date">{{ formatDate(publicationDate) }}</span>
      </div>
    </div>
    <div class="article-content" v-html="content"></div>
  </div>
</template>

<script>
import { ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
  name: 'CommonArticle',
  props: {
    contentId: {
      type: String,
      required: true
    },
    titles: {
      type: Object,
      required: true
    },
    publicationDate: {
      type: Date,
      required: true
    }
  },
  setup(props) {
    const { locale } = useI18n()
    const content = ref('')
    const currentTitle = ref('')

    const loadContent = async () => {
      try {
        const response = await fetch(`/articles/content/${locale.value}/${props.contentId}.html`)
        content.value = await response.text()
        currentTitle.value = props.titles[locale.value] || props.titles['de'] // fallback to German
      } catch (error) {
        console.error('Failed to load article content:', error)
        // Fallback to default language if current language content is not available
        if (locale.value !== 'de') {
          const fallbackResponse = await fetch(`/articles/content/de/${props.contentId}.html`)
          content.value = await fallbackResponse.text()
          currentTitle.value = props.titles['de']
        }
      }
    }

    watch(locale, loadContent)
    loadContent() // Initial load

    return {
      content,
      currentTitle,
      formatDate: (date) => {
        return new Intl.DateTimeFormat(locale.value, {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        }).format(date)
      }
    }
  }
}
</script>

<style>
.article-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.article-header {
  margin-bottom: 2rem;
}

.article-meta {
  color: #666;
  font-size: 0.9rem;
}

.article-content {
  line-height: 1.6;
}

h1 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

/* Article content specific styles */
.article-content .article-main-image {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  border-radius: 8px;
  margin: 1rem 0 2rem 0;
}

.article-content h2 {
  margin: 2rem 0 1rem 0;
  color: #2c3e50;
}

.article-content ul,
.article-content ol {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.article-content li {
  margin-bottom: 0.5rem;
}

.article-content p {
  margin-bottom: 1rem;
  color: black;
}
</style> 