// Google Analytics event tracking utility functions

export const isAnalyticsEnabled = () => {
  return process.env.VUE_APP_GA_ENABLED === 'true' && 
         window.gtag && 
         window.googleAnalytics === true;  // Check Klaro consent
};

export const trackEvent = (eventName, eventParams = {}) => {
  if (isAnalyticsEnabled()) {
    gtag('event', eventName, eventParams);
  }
};

// Pre-defined event tracking functions
export const trackImageUpload = (fileSize, success = true) => {
  trackEvent('image_upload', {
    file_size: fileSize,
    status: success ? 'success' : 'failed'
  });
};

export const trackSignupDialogOpen = () => {
  trackEvent('signup_dialog_open', {
    source: window.location.pathname
  });
};

export const trackSignupSubmit = (success = true) => {
  trackEvent('signup_submit', {
    status: success ? 'success' : 'failed'
  });
};
