<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1 class="text-h3 mb-6">{{ $t('imprint.title') }}</h1>
        <v-card class="pa-6">
          <h2 class="text-h5 mb-4">{{ $t('imprint.legal_info.title') }}</h2>
          <p>
            {{ $t('imprint.legal_info.company') }}<br>
            {{ $t('imprint.legal_info.name') }}<br>
            {{ $t('imprint.legal_info.address.street') }}<br>
            {{ $t('imprint.legal_info.address.city') }}<br>
            {{ $t('imprint.legal_info.address.country') }}
          </p>

          <h2 class="text-h5 mb-4 mt-6">{{ $t('imprint.contact.title') }}</h2>
          <p>{{ $t('imprint.contact.email') }}</p>
          <p>{{ $t('imprint.contact.phone') }}</p>

          <h2 class="text-h5 mb-4 mt-6">{{ $t('imprint.data_protection.title') }}</h2>
          <p>
            {{ $t('imprint.data_protection.content') }}<br>
            {{ $t('imprint.data_protection.email') }}
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Impressum'
}
</script>
