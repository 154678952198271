import { initializeAnalytics } from '../plugins/consent-manager';

// Klaro configuration
export const klaroConfig = {
    // With cookie consent, Google Analytics shouldn't start automatically
    googleAnalytics: false,

    // Styling
    styling: {
        theme: ['light', 'top', 'wide'],
    },

    // General configuration
    acceptAll: true,
    hideDeclineAll: false,
    hideLearnMore: false,
    noticeAsModal: false,
    default: false,         // Default to opt-out
    htmlTexts: true,        // Allow HTML in texts
    embedded: false,        // Not embedded in another page
    hideToggleAll: true,   // Hide the "toggle all" checkbox
    hideFooter: true,      // Hide the footer completely
    poweredBy: null,       // Remove powered by text completely
    storageMethod: 'cookie',
    storageName: 'grundriss_ai_cookie_consent',
    cookieExpiresAfterDays: 365,

    // Translations
    translations: {
        de: {
            privacyPolicyUrl: '/datenschutz',
            consentModal: {
                title: 'Informationen die wir sammeln',
                description: 'Hier können Sie einsehen und anpassen, welche Information wir über Sie sammeln.\n',
            },
            consentNotice: {
                description: 'Wir verwenden Cookies und ähnliche Technologien, um Ihre Erfahrung auf unserer Website zu verbessern.',
                learnMore: 'Mehr erfahren',
            },
            purposes: {
                analytics: 'Besucher-Statistiken',
            },
            ok: 'Alle akzeptieren',
            acceptAll: 'Alle akzeptieren',
            acceptSelected: 'Ausgewählte akzeptieren',
            decline: 'Ablehnen',
            save: 'Speichern',
            service: {
                purpose: 'Zweck',
                purposes: 'Zwecke',
            }
        }
    },

    // Services configuration
    services: [
        {
            name: 'google-analytics',
            title: 'Google Analytics',
            purposes: ['analytics'],
            required: false,
            default: false,
            optOut: true,
            onlyOnce: true,
            cookies: [
                [/^_ga.*$/i, '/', 'grundriss-ai.de'],  // All cookies that start with _ga
                [/^_gid.*$/i, '/', 'grundriss-ai.de'], // All cookies that start with _gid
            ],
            callback: function(consent) {
                // Enable GA if consent is true
                window.googleAnalytics = consent;
                if (consent) {
                    initializeAnalytics().catch(error => {
                        console.error('Failed to initialize analytics after consent:', error);
                    });
                }
            },
            translations: {
                de: {
                    title: 'Google Analytics',
                    description: 'Hilft uns zu verstehen, wie Besucher mit unserer Website interagieren. Wir erfassen dabei:\n' +
                               '- Bildupload-Events (Dateigröße und Status)\n' +
                               '- Registrierungsdialog-Öffnungen\n' +
                               '- Registrierungsversuche\n\n' +
                               'Alle Daten werden anonymisiert erfasst.'
                }
            }
        }
    ]
};
