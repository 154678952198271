import { createI18n } from 'vue-i18n'
import { detectUserLanguage } from '@/utils/languageUtils'
import en from '../locales/en.json'
import de from '../locales/de.json'

const userLanguage = detectUserLanguage()

export default createI18n({
  legacy: false, // Set to false to use Composition API
  locale: userLanguage, // set locale based on user preference
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    de
  }
})
