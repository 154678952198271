<template>
  <div class="consent-manager">
    <v-checkbox
      v-model="consent.marketing"
      :rules="[rules.required]"
      density="compact"
      color="primary"
      hide-details="auto"
      class="consent-checkbox"
    >
      <template v-slot:label>
        <span class="consent-text text-left">
          {{ t('consent.marketing') }}
        </span>
      </template>
    </v-checkbox>

    <v-checkbox
      v-model="consent.privacy"
      :rules="[rules.required]"
      density="compact"
      color="primary"
      hide-details="auto"
      class="consent-checkbox"
    >
      <template v-slot:label>
        <span 
          class="consent-text text-left" 
          v-html="privacyText"
          @click="handlePrivacyClick"
        ></span>
      </template>
    </v-checkbox>

    <div class="text-caption text-grey mt-4 text-left">
      {{ t('consent.dataUsage') }}
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const { t } = useI18n();
const emit = defineEmits(['update:consent']);

const consent = ref({
  marketing: false,
  privacy: false
});

const rules = {
  required: value => !!value || t('consent.required')
};

const privacyText = computed(() => {
  return t('consent.privacy', [
    `<a href="#" class="text-primary text-decoration-none">${t('consent.privacyLink')}</a>`
  ]);
});

// Handle click events on the privacy link
const handlePrivacyClick = (event) => {
  // Check if the clicked element is the privacy link
  if (event.target.tagName.toLowerCase() === 'a') {
    event.preventDefault();
    router.push('/datenschutz');
  }
};

// Watch for changes in consent and emit to parent
watch(consent, (newValue) => {
  emit('update:consent', newValue);
}, { deep: true });

// Expose isValid method to parent
defineExpose({
  isValid: () => consent.value.marketing && consent.value.privacy
});
</script>

<style scoped>
.consent-manager {
  margin-top: 96px;
  text-align: left;
}

.consent-checkbox {
  margin-top: 8px !important;
}

.consent-checkbox :deep(.v-selection-control) {
  margin-right: 8px;
  align-self: flex-start;
  margin-top: 2px;
}

.consent-text {
  font-size: 0.875rem;
  line-height: 1.4;
  display: inline-block;
  text-align: left;
}

:deep(.v-checkbox .v-label) {
  opacity: 1;
  text-align: left;
}

:deep(.v-checkbox) {
  align-items: flex-start !important;
}
</style>
