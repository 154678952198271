<template>
  <v-row id="pricing" class="pricing-section py-12">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center mb-8">
          <h2 class="text-h1 text-sm-h3 text-xs-h3 font-weight-bold">{{ $t('pricing.title') }}</h2>
          <p class="text-subtitle-1 mt-2">{{ $t('pricing.subtitle') }}</p>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-4">
        <v-col
          v-for="(plan, index) in plans"
          :key="index"
          cols="12"
          sm="10"
          md="6"
          lg="5"
          class="d-flex"
        >
          <v-card 
            class="price-card d-flex flex-column"
            :class="{ 'popular': plan.popular }"
            :data-popular-text="$t('pricing.popular_badge')"
            elevation="2"
            width="100%"
          >
            <v-card-item>
              <div class="text-center">
                <h3 class="text-h5 font-weight-bold mb-4">{{ $t(plan.nameKey) }}</h3>
                <div class="price-container mb-2">
                  <span class="text-h3 font-weight-bold">{{ plan.price }}</span>
                  <span class="text-h5 ml-1">€</span>
                </div>
                <div class="text-subtitle-1 mb-6">{{ $t(plan.billingKey) }}</div>
              </div>

              <v-divider class="mb-6"></v-divider>

              <v-list density="comfortable" class="bg-transparent">
                <v-list-item
                  v-for="(feature, fIndex) in plan.features"
                  :key="fIndex"
                  class="px-2 mb-2"
                >
                  <template v-slot:prepend>
                    <v-icon :color="feature.included ? 'success' : 'grey'" size="small" class="mr-3">
                      {{ feature.included ? 'mdi-check-circle' : 'mdi-circle-outline' }}
                    </v-icon>
                  </template>
                  <v-list-item-title 
                    :class="{ 'text-medium-emphasis': !feature.included }"
                    class="text-body-1 text-wrap"
                    style="white-space: normal; line-height: 1.5"
                  >
                    {{ $t(feature.textKey) }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card-item>

            <v-card-actions class="mt-auto pa-4">
              <v-btn
                block
                :color="plan.popular ? 'primary' : 'default'"
                :variant="plan.popular ? 'flat' : 'outlined'"
                size="large"
                @click="openModal"
              >
                {{ $t(plan.buttonTextKey) }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <SignupDialog v-model="showModal" />
  </v-row>
</template>

<script setup>
import { ref } from 'vue';
import SignupDialog from '@/components/common/SignupDialog.vue';
import { trackSignupDialogOpen } from '@/utils/analytics';

const showModal = ref(false);

const openModal = () => {
  showModal.value = true;
  trackSignupDialogOpen();
};

const plans = [
  {
    nameKey: 'pricing.plans.basic.name',
    price: '0',
    billingKey: 'pricing.free',
    buttonTextKey: 'pricing.start_now',
    features: [
      { textKey: 'pricing.plans.basic.features.free_analysis', included: true },
      { textKey: 'pricing.plans.basic.features.basic_ai', included: true },
      { textKey: 'pricing.plans.basic.features.basic_recommendations', included: true },
      { textKey: 'pricing.plans.basic.features.detailed_recommendations', included: false },
      { textKey: 'pricing.plans.basic.features.floor_plan_search', included: false },    
      { textKey: 'pricing.plans.basic.features.export', included: false },
      { textKey: 'pricing.plans.basic.features.support', included: false }
    ]
  },
  {
    nameKey: 'pricing.plans.credits.name',
    price: '7,99',
    billingKey: 'pricing.plans.credits.billing',
    popular: true,
    buttonTextKey: 'pricing.buy_credits',
    features: [
      { textKey: 'pricing.plans.credits.features.included_analysis', included: true },
      { textKey: 'pricing.plans.credits.features.advanced_ai', included: true },      
      { textKey: 'pricing.plans.basic.features.basic_recommendations', included: true },
      { textKey: 'pricing.plans.basic.features.detailed_recommendations', included: true },
      { textKey: 'pricing.plans.basic.features.floor_plan_search', included: true },      
      { textKey: 'pricing.plans.basic.features.export', included: true },
      { textKey: 'pricing.plans.basic.features.support', included: true }
    ]
  }
];
</script>

<style scoped>
.pricing-section {
  background-color: var(--background);
}

.price-card {
  height: 100%;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  border: 1px solid var(--border-color);
  padding: 1rem;
  background: white;
}

.price-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.price-card.popular {
  border: 2px solid var(--primary);
  position: relative;
}

.price-card.popular::before {
  content: attr(data-popular-text);
  position: absolute;
  top: 8px;
  right: 8px;
  background: var(--primary);
  color: white;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.875rem;
  font-weight: 500;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
  color: var(--primary);
}

.popular .price-container {
  color: var(--primary-light);
}

.text-wrap {
  word-break: break-word;
  color: var(--text-primary);
}

.text-medium-emphasis {
  color: var(--text-secondary) !important;
}

:deep(.v-btn) {
  text-transform: none;
  font-weight: 600;
  letter-spacing: 0;
  border-radius: 8px;
  padding: 8px 24px;
  height: 48px;
}

:deep(.v-btn.v-btn--variant-flat) {
  background: white;
  color: var(--primary);
}

:deep(.v-btn.v-btn--variant-outlined) {
  border-color: var(--primary);
  color: var(--primary);
}

.text-h4 {
  font-weight: 700;
  color: var(--text-primary);
}

.text-subtitle-1 {
  color: var(--text-secondary);
}

.v-list-item-title {
  font-size: 0.9375rem;
  line-height: 1.5;
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.75rem !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  h2 {
    font-size: 2.125rem !important;
  }
}
</style>
